import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdType } from 'react-table';
import styled from 'styled-components';
import {
  TaskListConfigurationDTO2,
  TaskListRequestDTO,
  WorkTaskListItemDTO3,
  WorkTaskStatus,
  TaskListResponseDTO,
  TaskListCustomFilterDTO2,
  OrderByEnum,
  OrderByColumnEnum,
  DepartmentDTO,
  TaskListCustomFilterDTO,
  TaskListCustomFilterDTO2Type
} from '../../../api/api';
import Table from '../../../blocks/table/TableServer';
import Button from '../../../components/button/Button';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import Typography from '../../../components/typography/Typography';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { useNavigate } from '../../../hooks/useNavigate';
import NotificationService from '../../../services/NotificationService';
import TaskService from '../../../services/TaskService';
import UserService from '../../../services/UserService';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { newTask, selectTasks } from '../../../stateManagement/reducers/taskCreationReducer';
import {
  selectColumnConfigs,
  selectCustomFilters,
  selectFilterValues,
  selectListShouldUpdate,
  selectOperationBaseFilter,
  selectSelectedCustomFilter,
  selectSelectedCustomFilters,
  setColumConfig,
  setCustomFilters,
  setFilterValues,
  setListShouldUpdate,
  setSelectedAllCustomFilter,
  setSelectedCustomFilter,
  setShowFilterButton
} from '../../../stateManagement/reducers/taskListReducer';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { GreyAreaHorizontalPadding } from '../../../styling/StylingConstants';
import { EDIT_TASK_ROUTE, MAP_TASK_LIST_ROUTE, MASS_CREATION } from '../../../utils/constants';
import { columnAccessorsToColumnEnum, getEnumDisplayValue } from '../../../utils/enumUtils';
import { getGuid } from '../../../utils/guidGenerating';
import { log } from '../../../utils/logging/log';
import { ColumnAccessors, DefaultTabs, TaskData, useTaskList } from './useTaskList';
import addQueryParams from '../../../utils/routing/add-query-params';
import SplitButton from '../../../components/split-button/SplitButton';
import { useNavigateToTask } from '../../../hooks/useNavigateToTask';
import {
  getFilterValues,
  getUniqueIdFromCustomFilter,
  isInPlanningLoop,
  transformTaskListRequestToCustomFilter2
} from './TaskListFilterUtils';
import WorkTaskControlBar from '../../../blocks/worktask-control-bar/WorkTaskControlBar';
import useUserAccess from '../../../hooks/useUserAccess';

const TaskList = () => {
  const [loading, setLoading] = useState(false);
  const [showBookButton, setShowBookButton] = useState(false);
  const openedTasks = useSelector(selectTasks);
  const shouldUpdate = useSelector(selectListShouldUpdate);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToTask = useNavigateToTask();

  const userProfile = useSelector(selectUserProfile).userProfile;
  const userProfileLoaded = useSelector(selectUserProfile).isLoaded;
  const userDepartment = userProfile?.department;

  const [tasks, setTasks] = useState<WorkTaskListItemDTO3[]>([]);
  const { tableInstance, excelRowFilter, tabs, activeTab, changeTab } = useTaskList(tasks);
  const { selectedFlatRows, toggleAllRowsSelected, visibleColumns } = tableInstance;
  const { officeUserGroupAllowed } = useUserAccess();

  const customFilters = useSelector(selectCustomFilters);
  const [updatingFilter, setUpdatingFilter] = useState(false);
  const columnConfigs = useSelector(selectColumnConfigs);
  const [tableConfig, setTableConfig] = useState<TaskListResponseDTO>({} as TaskListResponseDTO);
  const [firstFetch, setFirstFetch] = useState(true);

  const { getConfirmation } = useConfirmationDialog();
  const filterValues = useSelector(selectFilterValues);
  const selectedCustomFilters = useSelector(selectSelectedCustomFilters);
  const selectedCustomFilter = useSelector(selectSelectedCustomFilter);

  const operationBaseFilter = useSelector(selectOperationBaseFilter);

  useEffect(() => {
    dispatch(setFilterValues(operationBaseFilter));
  }, [dispatch, operationBaseFilter]);

  const controllerRef = useRef<AbortController | null>(null);

  const fetchTasks = useCallback(
    (body?: TaskListRequestDTO) => {
      setLoading(true);

      body && dispatch(setFilterValues(body));
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      TaskService.getWorkTaskList(body ?? filterValues, controllerRef.current)
        .then((res) => {
          setTasks(res.tasks ?? ([] as WorkTaskListItemDTO3[]));
          setTableConfig({ ...res, page: res?.page && res?.page - 1 });
          dispatch(setListShouldUpdate(false));
          setLoading(false);
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            log('Request aborted');
          } else {
            log(err);
            NotificationService.error('Kunne ikke indlæse opgavelisten.');
            setLoading(false);
          }
        });

      return () => {
        // Abort the request when the component unmounts or when a dependency changes
        controllerRef.current?.abort();
      };
    },
    [dispatch, filterValues]
  );

  const getAllFilteredTasks = useCallback(() => {
    fetchTasks({ ...filterValues, pageSize: tableConfig.totalCount });
  }, [fetchTasks, filterValues, tableConfig.totalCount]);

  useEffect(() => {
    if (selectedCustomFilters && selectedCustomFilters.operation !== undefined && firstFetch) {
      changeTab(userDepartment, DefaultTabs.Drift.id);
      setFirstFetch(false);
    } else if (operationBaseFilter.taskStatusFilter !== undefined && firstFetch) {
      dispatch(setSelectedCustomFilter(undefined));
      fetchTasks(operationBaseFilter);
      setFirstFetch(false);
    } else if (!firstFetch) {
      fetchTasks();
    }
  }, [changeTab, dispatch, fetchTasks, firstFetch, operationBaseFilter, selectedCustomFilters, userDepartment]);

  useEffect(() => {
    if (shouldUpdate) {
      fetchTasks();
    }
  }, [shouldUpdate, fetchTasks]);

  const handleUpdateTasks = useCallback(() => {
    fetchTasks(filterValues);
  }, [fetchTasks, filterValues]);

  const handleDeleteTasks = useCallback(
    async (worktaskHasDifferentDepartment) => {
      const body: number[] = selectedFlatRows.map((row) => parseInt(row.original.id));

      if (isInPlanningLoop(selectedFlatRows.map((row) => row.original.status))) {
        NotificationService.error(`Kunne ikke annullere opgave(r). En eller flere opgaver er i planning loopet.`);
        return;
      }
      const dialogBody: DialogBody = {
        headerText:
          body.length > 1
            ? `Er du sikker på at du vil annullere ${body.length} opgaver?`
            : `Er du sikker på at du vil annullere opgaven?`,
        bodyText: `Annullerede opgaver kan stadigvæk findes på “Alle opgaver”, men skal oprettes igen hvis annulleringen fortrydes. ${
          worktaskHasDifferentDepartment ? 'Mindst én af de valgte opgaver er tildelt en anden afdeling.' : ''
        }`,
        declineButtonText: 'Fortryd',
        confirmButtonText: 'Ja'
      };

      const confirmation = await getConfirmation(dialogBody);

      if (confirmation === 'confirm') {
        setLoading(true);
        TaskService.cancelTasks(body)
          .then(() => {
            dispatch(setListShouldUpdate(true));

            NotificationService.success(`Annullerede ${body.length} opgave(r).`);
            setLoading(false);
          })
          .catch((error) => {
            NotificationService.error(`Der opstod en fejl ved annullering af opgaver: ${error}`);
            setLoading(false);
          });
      }
    },
    [selectedFlatRows, getConfirmation, dispatch]
  );

  const handleClose = useCallback(() => {
    toggleAllRowsSelected(false);
  }, [toggleAllRowsSelected]);

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      setShowBookButton(
        selectedFlatRows.every(
          (row) =>
            row.original.status === getEnumDisplayValue(WorkTaskStatus.Created) ||
            row.original.status === getEnumDisplayValue(WorkTaskStatus.Pause)
        )
      );
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (activeTab) {
      const taskListConfiguration = columnConfigs.find((c) => c.tagId === activeTab?.id);
      const columnOrder = taskListConfiguration?.columnOrder ?? [];
      columnOrder && tableInstance.setColumnOrder(columnOrder);

      const hiddenColumns = taskListConfiguration?.hiddenColumns ?? [];
      hiddenColumns.forEach((column) => tableInstance.toggleHideColumn(column, true));
    }
  }, [activeTab, columnConfigs, tableInstance]);

  const handleDoubleClick = useCallback(
    (rowData: TaskData) => {
      if (!rowData.baseWorkTaskType) return;
      navigateToTask(parseInt(rowData.id), rowData.baseWorkTaskType);
    },
    [navigateToTask]
  );

  const getExcelExportFileName = useCallback(() => {
    return `WORK opgaveliste - ${new Date().toLocaleDateString('da-DK', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}.csv`;
  }, []);

  const handleColumnFilterChange = useCallback(
    (columns: IdType<TaskData>[]) => {
      let firstConfig = false;
      let config: TaskListConfigurationDTO2 | undefined = columnConfigs.find((c) => c.tagId === activeTab?.id);

      if (!config) {
        firstConfig = true;
        const tmpColumns = visibleColumns.map((c) => c.id);
        config = { tagId: activeTab?.id, columnOrder: tmpColumns, hiddenColumns: columns };
      }

      let fValues = filterValues;
      columns.forEach((c) => {
        fValues = getFilterValues(fValues, undefined, c);
      });
      fetchTasks(fValues);

      const newConfig = { ...config, hiddenColumns: columns };
      UserService.setTaskListColumnConfiguration(newConfig)
        .then(() => {
          const updatedItems =
            firstConfig && config
              ? [...columnConfigs, config]
              : columnConfigs.map((c) => (c.tagId === newConfig.tagId ? newConfig : c));
          dispatch(setColumConfig(updatedItems));
        })
        .catch(() => {
          NotificationService.error('De netop tilføjede skjulte/viste kolonner er ikke blevet gemt.');
        });
    },
    [activeTab?.id, columnConfigs, dispatch, fetchTasks, filterValues, visibleColumns]
  );

  const handleColumnSwap = useCallback(
    (dragItem?: number, dragOverItem?: number) => {
      if (dragItem === undefined || dragOverItem === undefined) return;

      const tmpColumns = visibleColumns.map((c) => c.id);
      const dragColumn = tmpColumns.splice(dragItem, 1)[0];
      tmpColumns.splice(dragOverItem, 0, dragColumn);

      tableInstance.setColumnOrder(tmpColumns);

      let firstConfig = false;
      let config = columnConfigs.find((c) => c.tagId === activeTab?.id);
      if (!config) {
        firstConfig = true;
        config = { tagId: activeTab?.id, columnOrder: tmpColumns, hiddenColumns: [] };
      }

      const newConfig = { ...config, columnOrder: tmpColumns };
      UserService.setTaskListColumnConfiguration(newConfig)
        .then(() => {
          const updatedItems =
            firstConfig && config
              ? [...columnConfigs, config]
              : columnConfigs.map((c) => (c.tagId === newConfig.tagId ? newConfig : c));
          dispatch(setColumConfig(updatedItems));
        })
        .catch(() => {
          NotificationService.error('Den netop ændrede kolonnerækkefølge er ikke blevet gemt.');
        });
    },
    [visibleColumns, tableInstance, activeTab?.id, columnConfigs, dispatch]
  );

  const handleSelectCustomFilter = useCallback(
    (customFilter: TaskListCustomFilterDTO2 | undefined, tabId?: number) => {
      const isDepartmentFilter =
        customFilter?.type === TaskListCustomFilterDTO2Type.Department ||
        customFilter?.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks;
      const isAllTab = tabId && tabId === DefaultTabs.All.id;
      const tagId = isAllTab ? undefined : tabId ?? DefaultTabs.Drift.id;

      if (isAllTab) {
        customFilter &&
          customFilter.name &&
          UserService.selectTaskListAllCustomFilter(
            customFilter.name,
            isDepartmentFilter ? userDepartment?.departmentId : undefined
          );
        dispatch(setSelectedAllCustomFilter(customFilter));
      } else {
        customFilter &&
          customFilter.name &&
          UserService.selectTaskListCustomFilter(
            customFilter.name,
            isDepartmentFilter ? userDepartment?.departmentId : undefined
          );
        dispatch(setSelectedCustomFilter(customFilter));
      }

      const selectedCustomFilterValues = {
        pageSize: filterValues.pageSize,
        sortOrder: OrderByEnum.Desc,
        ...(customFilter as TaskListRequestDTO),
        page: 1,
        tagId: tagId
      } as TaskListRequestDTO;

      fetchTasks(selectedCustomFilterValues);
    },
    [dispatch, fetchTasks, filterValues.pageSize, userDepartment?.departmentId]
  );

  const handleSaveCurrentFilter = useCallback(
    (name: string, onSuccessFulSave: () => void, type: TaskListCustomFilterDTO2Type) => {
      if (name === '') {
        NotificationService.error('Angiv et navn på filteret før det gemmes.');
        return;
      }
      setUpdatingFilter(true);

      const isDepartmentFilter =
        type === TaskListCustomFilterDTO2Type.Department || type === TaskListCustomFilterDTO2Type.DepartmentAllTasks;
      const newFilterValues = transformTaskListRequestToCustomFilter2(filterValues, name, type);

      UserService.addTaskListCustomFilter(newFilterValues, userProfile.department?.departmentId)
        .then(() => {
          NotificationService.success('Filteret blev gemt');
          isDepartmentFilter
            ? dispatch(setCustomFilters([...customFilters, newFilterValues]))
            : dispatch(setCustomFilters([newFilterValues, ...customFilters]));

          activeTab?.id === DefaultTabs.All.id
            ? dispatch(setSelectedAllCustomFilter(newFilterValues))
            : dispatch(setSelectedCustomFilter(newFilterValues));

          onSuccessFulSave();
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Filteret kunne ikke gemmes. Prøv at ændre navnet på filteret.');
        })
        .finally(() => setUpdatingFilter(false));
    },
    [activeTab?.id, customFilters, dispatch, filterValues, userProfile.department?.departmentId]
  );

  const handleDeleteFilter = useCallback(
    (filter: TaskListCustomFilterDTO2) => {
      if (!filter.name) return;
      setUpdatingFilter(true);

      const isDepartmentFilter =
        filter.type === TaskListCustomFilterDTO2Type.Department ||
        filter.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks;
      UserService.deleteTaskListCustomFilter(filter.name, isDepartmentFilter, userDepartment?.departmentId)
        .then(() => {
          dispatch(
            setCustomFilters(
              customFilters?.filter((f) => getUniqueIdFromCustomFilter(f) !== getUniqueIdFromCustomFilter(filter)) ?? []
            )
          );
          if (filter.isSelected) {
            activeTab?.id === DefaultTabs.All.id
              ? dispatch(setSelectedAllCustomFilter(undefined))
              : dispatch(setSelectedCustomFilter(undefined));
          }
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Filteret kunne ikke slettes');
        })
        .then(() => {
          setUpdatingFilter(false);
        });
    },
    [activeTab?.id, customFilters, dispatch, userDepartment?.departmentId]
  );

  const handleUpdateFilter = useCallback(
    (filter: TaskListCustomFilterDTO2) => {
      setUpdatingFilter(true);

      const isDepartmentFilter =
        filter.type === TaskListCustomFilterDTO2Type.Department ||
        filter.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks;
      const newFilterValues = transformTaskListRequestToCustomFilter2(filterValues, filter.name, filter.type);

      const updatedCustomFilters = customFilters.map((f) => {
        if (f.name === newFilterValues.name && f.type === newFilterValues.type) return newFilterValues;
        return f;
      });

      UserService.updateTaskListCustomFilter(
        newFilterValues as TaskListCustomFilterDTO,
        isDepartmentFilter,
        userProfile.department?.departmentId
      )
        .then(() => {
          NotificationService.success(`Filteret: ${newFilterValues.name} blev opdateret`);
          dispatch(setCustomFilters(updatedCustomFilters));
          activeTab?.id === DefaultTabs.All.id
            ? dispatch(setSelectedAllCustomFilter(newFilterValues))
            : dispatch(setSelectedCustomFilter(newFilterValues));
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Filteret kunne ikke opdateres');
        })
        .finally(() => setUpdatingFilter(false));
    },
    [activeTab?.id, customFilters, dispatch, filterValues, userProfile.department?.departmentId]
  );

  const handleMapViewClick = useCallback(() => {
    navigate('/' + MAP_TASK_LIST_ROUTE);
  }, [navigate]);

  const createNewTaskHandler = (massCreation: boolean) => {
    if (massCreation) {
      navigate(MASS_CREATION);
      return;
    }

    if (openedTasks.length < 3) {
      const taskId = getGuid();
      dispatch(newTask({ taskCreationId: taskId }));
      navigate(addQueryParams(undefined, { id: taskId }, EDIT_TASK_ROUTE));
    } else {
      NotificationService.warning('Der kan højst være 3 åbne arbejdskort. Luk venligst en af de åbne arbejdskort.');
    }
  };

  const handleClearFilters = useCallback(
    (userDepartment: DepartmentDTO, tabId?: number) => {
      const allTasks = !!(tabId && tabId === DefaultTabs.All.id) ?? false;
      const tagId = allTasks ? undefined : tabId;

      let clearFilterValues = {
        page: 1,
        pageSize: filterValues.pageSize,
        sortOrder: OrderByEnum.Desc,
        tagId: tagId
      } as TaskListRequestDTO;

      if (!allTasks && userDepartment) {
        clearFilterValues = {
          ...operationBaseFilter,
          tagId: tagId
        } as TaskListRequestDTO;
        UserService.selectTaskListCustomFilter();
        dispatch(setSelectedCustomFilter(undefined));
      }

      if (allTasks) {
        UserService.selectTaskListAllCustomFilter();
        dispatch(setSelectedAllCustomFilter(undefined));
      }

      dispatch(setFilterValues(clearFilterValues));
      dispatch(setShowFilterButton(false));
    },
    [dispatch, filterValues.pageSize, operationBaseFilter]
  );

  const getCustomFilters = useCallback(() => {
    if (activeTab?.id === DefaultTabs.All.id) {
      return customFilters.filter(
        (c) =>
          c.type === TaskListCustomFilterDTO2Type.UserAllTasks || c.type === TaskListCustomFilterDTO2Type.DepartmentAllTasks
      );
    } else {
      return customFilters.filter(
        (c) => c.type === TaskListCustomFilterDTO2Type.User || c.type === TaskListCustomFilterDTO2Type.Department
      );
    }
  }, [activeTab?.id, customFilters]);

  return (
    <>
      <HeaderContainer>
        <Typography variant="h2">Opgaver</Typography>
        <Row>
          {officeUserGroupAllowed() ? (
            <ButtonContainer>
              <Button variant="secondary" onClick={() => handleMapViewClick()}>
                Kortvisning
              </Button>
              <SplitButton
                options={[
                  { label: 'Opret opgave', onClick: () => createNewTaskHandler(false) },
                  { label: 'Masseoprettelse', onClick: () => createNewTaskHandler(true) }
                ]}
              />
            </ButtonContainer>
          ) : (
            <Button onClick={() => createNewTaskHandler(false)}>Opret opgave</Button>
          )}
        </Row>
      </HeaderContainer>
      {userProfileLoaded && userDepartment !== undefined ? (
        <Table
          disabledWorkTaskIds={tasks
            .filter((row) => row.status === WorkTaskStatus.Canceled)
            .map((row) => parseInt(row.id ?? ''))}
          activeTab={activeTab}
          loading={loading}
          tableInstance={tableInstance}
          noDataText="Fandt ingen opgaver"
          multipleTypeName="opgaver"
          onDoubleClickRow={handleDoubleClick}
          excelExportFileNameFunction={getExcelExportFileName}
          loadAllTasks={getAllFilteredTasks}
          onUpdateTable={handleUpdateTasks}
          showColumnFiltering
          showCustomFiltering
          customFilters={getCustomFilters()}
          onSelectCustomFilter={(filter) => handleSelectCustomFilter(filter, activeTab?.id)}
          onSaveFilter={handleSaveCurrentFilter}
          onDeleteFilter={handleDeleteFilter}
          onUpdateFilter={handleUpdateFilter}
          updatingFilter={updatingFilter}
          showExcelExport
          showUpdateTable
          showClearFilter
          onClearFilters={() => handleClearFilters(userDepartment, activeTab?.id)}
          onColumFilterChange={handleColumnFilterChange}
          tabs={tabs}
          excelExportRowFilterFunction={excelRowFilter}
          showPagination
          showCount
          onColumnSwap={handleColumnSwap}
          sortingOrder={filterValues.sortOrder}
          sortingColumn={filterValues.orderByColumn?.toString()}
          onSortingOrder={(orderByColumn: string, sortOrder: OrderByEnum) => {
            fetchTasks({
              ...filterValues,
              orderByColumn: columnAccessorsToColumnEnum(orderByColumn as ColumnAccessors) as OrderByColumnEnum,
              sortOrder: sortOrder
            });
          }}
          tableConfig={tableConfig}
          selectedFilter={selectedCustomFilter}
          tableMenuBar={
            <WorkTaskControlBar
              selectedWorkTasks={selectedFlatRows.map((s) => {
                return {
                  workTaskId: s.original.id,
                  hasSubTasks: s.original.hasSubTasks ?? false,
                  status: s.original.status,
                  department: s.original.department
                };
              })}
              updateTasks={handleUpdateTasks}
              handleClose={handleClose}
              setLoading={(isLoading: boolean) => setLoading(isLoading)}
              showBookButton={showBookButton}
              handleDeleteTasks={handleDeleteTasks}
            />
          }
        />
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${GreyAreaHorizontalPadding}px ${GreyAreaHorizontalPadding}px 0px;
  line-height: 40px;
`;

const Row = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export default TaskList;
