import { useMatch, useNavigate as useNavigateReact } from 'react-router';
import styled from 'styled-components';

import { ClockIcon } from '../../../assets/icons/ClockIcon';
import { ListIcon } from '../../../assets/icons/ListIcon';
import {
  ADMIN_QUESTIONS,
  ADMIN_REASONCODES,
  ADMIN_REUSABLECOMPONENTS,
  ADMIN_TASKTYPES,
  GOODS_ROUTE,
  INTERNAL_TIME_OFFICE,
  PORTAL_ROUTE,
  PROFILE_ROUTE,
  TASKS_ROUTE,
  TIME_ROUTE,
  CALENDAR_ROUTE,
  WORKPLAN_ROUTE,
  TASKS_DETAILS,
  MAP_TASK_LIST_ROUTE,
  ORDER_GOODS_ROUTE,
  COUNT_GOODS_ROUTE,
  MOVE_GOODS_ROUTE,
  ADMIN_PORTAL_LINKS,
  TECHNICIAN_TASKLIST_ROUTE,
  ADMIN_NEWS,
  NEWS_ROUTE,
  RETURN_GOODS_ROUTE,
  RETURN_RECEIPT_ROUTE,
  ORDER_RECEIPT_ROUTE,
  ADMIN_DEPARTMENTS
} from '../../../utils/constants';
import { Badge } from '@mui/material';
import { VehicleIcon } from '../../../assets/icons/VehicleIcon';
import { UserProfileState } from '../../../stateManagement/reducers/userProfileReducer';
import { memo, useCallback } from 'react';
import { WorldIcon } from '../../../assets/icons/WorldIcon';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { InternalTimeIcon } from '../../../assets/icons/InternalTimeIcon';
import { ShopIcon } from '../../../assets/icons/ShopIcon';
import ContextMenuTab, { ContextMenuItem } from '../../../components/context-menu-tab/ContextMenuTab';
import Avatar from '../../../components/avatar/Avatar';
import { getUserInitials, msalInstance } from '../../../utils/authProvider/authProvider';
import { useNavigate } from '../../../hooks/useNavigate';
import { BuildIcon } from '../../../assets/icons/BuildIcon';
import addQueryParams from '../../../utils/routing/add-query-params';
import useUserAccess from '../../../hooks/useUserAccess';
import { ClipboardTextIcon } from '../../../assets/icons/ClipboardTextIcon';
import { NewsIcon } from '../../../assets/icons/NewsIcon';
import { useSelector } from 'react-redux';
import { selectNews } from '../../../stateManagement/reducers/newsReducer';
import N1Logo from '../../../assets/icons/N1_Logo_Secondary_400px_Sort_Gradient_RGB.svg';
import n1LightTheme from '../../../theme/light-theme';

interface Props {
  unapprovedTasks: number;
  userProfileState?: UserProfileState;
  createdInternalTasks: number;
}

const LeftMenu = (props: Props) => {
  const { unapprovedTasks, createdInternalTasks } = props;

  const navigateReact = useNavigateReact();
  const navigate = useNavigate();
  const { technicianUserGroupAllowed, officeUserGroupAllowed, adminUserGroupAllowed } = useUserAccess();
  const news = useSelector(selectNews)?.filter((n) => !n.isRead).length;

  const taskMatch = useMatch(TASKS_ROUTE);
  const workplanMatch = useMatch(WORKPLAN_ROUTE);
  const workplanHistoryMatch = useMatch(CALENDAR_ROUTE);
  const technicianTaskList = useMatch(TECHNICIAN_TASKLIST_ROUTE);
  const timeMatch = useMatch(TIME_ROUTE);
  const goodsMatch = useMatch(GOODS_ROUTE);
  const portalMatch = useMatch(PORTAL_ROUTE);
  const internalTimeOfficeMatch = useMatch(INTERNAL_TIME_OFFICE);
  const adminTaskTypesMatch = useMatch(ADMIN_TASKTYPES);
  const adminComponentsMatch = useMatch(ADMIN_REUSABLECOMPONENTS);
  const adminReasonCodesMatch = useMatch(ADMIN_REASONCODES);
  const adminQuestionsMatch = useMatch(ADMIN_QUESTIONS);
  const taskDetailMatch = useMatch(TASKS_DETAILS);
  const orderGoodsMatch = useMatch(ORDER_GOODS_ROUTE);
  const moveGoodsMatch = useMatch(MOVE_GOODS_ROUTE);
  const mapViewMatch = useMatch(MAP_TASK_LIST_ROUTE);
  const countGoodsMatch = useMatch(COUNT_GOODS_ROUTE);
  const returnGoodsMatch = useMatch(RETURN_GOODS_ROUTE);
  const receiptMatch = useMatch(RETURN_RECEIPT_ROUTE);
  const orderReceiptMatch = useMatch(ORDER_RECEIPT_ROUTE);
  const newsMatch = useMatch(NEWS_ROUTE);

  const handleOnClickLogOut = useCallback(() => {
    msalInstance.logoutRedirect();
  }, []);

  const handleNavigateProfile = useCallback(() => {
    navigate(addQueryParams(undefined, undefined, PROFILE_ROUTE));
  }, [navigate]);

  const isFullscreenView =
    taskDetailMatch ||
    orderGoodsMatch ||
    countGoodsMatch ||
    moveGoodsMatch ||
    returnGoodsMatch ||
    receiptMatch ||
    orderReceiptMatch;

  return isFullscreenView ? (
    <></>
  ) : (
    <Container>
      <TopContainer>
        <img src={N1Logo} alt="" />
      </TopContainer>
      <CenterContainer>
        <MenuItem isActive={!!newsMatch} onClick={() => navigateReact(NEWS_ROUTE)}>
          <Badge
            badgeContent={news}
            color="error"
            overlap="circular"
            sx={{
              '& .MuiBadge-badge': {
                height: 21,
                width: 21,
                fontSize: 11,
                top: -5,
                left: 20,
                position: 'absolute',
                backgroundColor: n1LightTheme.palette.functions.action.primary
              }
            }}
          >
            <NewsIcon color={!!newsMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40} />
          </Badge>

          <MenuItemText>Nyheder</MenuItemText>
        </MenuItem>
        {technicianUserGroupAllowed() && (
          <>
            <MenuItem isActive={!!workplanMatch} onClick={() => navigateReact(WORKPLAN_ROUTE)}>
              <VehicleIcon color={!!workplanMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40} />
              <MenuItemText>Opgaver</MenuItemText>
            </MenuItem>
            <MenuItem isActive={!!workplanHistoryMatch} onClick={() => navigateReact(CALENDAR_ROUTE)}>
              <CalendarIcon
                color={!!workplanHistoryMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40}
              />
              <MenuItemText>Kalender</MenuItemText>
            </MenuItem>
            {(!officeUserGroupAllowed() || adminUserGroupAllowed()) && (
              <MenuItem isActive={!!technicianTaskList} onClick={() => navigateReact(TECHNICIAN_TASKLIST_ROUTE)}>
                <ClipboardTextIcon
                  color={!!technicianTaskList ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40}
                />
                <MenuItemText>Opgaveliste</MenuItemText>
              </MenuItem>
            )}
          </>
        )}
        {officeUserGroupAllowed() && (
          <>
            <MenuItem isActive={!!taskMatch || !!mapViewMatch} onClick={() => navigateReact(TASKS_ROUTE)}>
              <ListIcon
                color={!!taskMatch || !!mapViewMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40}
              />
              <MenuItemText>Opgaver</MenuItemText>
            </MenuItem>
            <MenuItem isActive={!!internalTimeOfficeMatch} onClick={() => navigateReact(INTERNAL_TIME_OFFICE)}>
              <Badge
                badgeContent={createdInternalTasks > 0 ? createdInternalTasks : undefined}
                color="error"
                overlap="circular"
                sx={{
                  '& .MuiBadge-badge': {
                    height: 21,
                    width: 21,
                    fontSize: 11,
                    top: -3,
                    left: 20,
                    position: 'absolute',
                    backgroundColor: n1LightTheme.palette.functions.error.primary
                  }
                }}
              >
                <InternalTimeIcon
                  color={!!internalTimeOfficeMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40}
                />
              </Badge>

              <MenuItemText>Int. opgaver</MenuItemText>
            </MenuItem>
          </>
        )}
        <MenuItem isActive={!!goodsMatch} onClick={() => navigateReact(GOODS_ROUTE)}>
          <ShopIcon color={!!goodsMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40} />
          <MenuItemText>Varer</MenuItemText>
        </MenuItem>
        <MenuItem isActive={!!timeMatch} onClick={() => navigateReact(TIME_ROUTE)}>
          <Badge
            badgeContent={unapprovedTasks > 0 ? unapprovedTasks : undefined}
            color="error"
            overlap="circular"
            sx={{
              '& .MuiBadge-badge': {
                height: 21,
                width: 21,
                fontSize: 11,
                top: -2,
                left: 20,
                position: 'absolute',
                backgroundColor: n1LightTheme.palette.functions.error.primary
              }
            }}
          >
            <ClockIcon color={!!timeMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40} />
          </Badge>
          <MenuItemText>Timer</MenuItemText>
        </MenuItem>
        {adminUserGroupAllowed() && (
          <MenuItem
            isActive={!!adminTaskTypesMatch || !!adminComponentsMatch || !!adminReasonCodesMatch || !!adminQuestionsMatch}
          >
            <ContextMenuTab
              menuItems={
                <>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_NEWS)}>Nyheder</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_DEPARTMENTS)}>Afdelinger</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_TASKTYPES)}>Opgavetyper</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_REUSABLECOMPONENTS)}>Typefelter</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_PORTAL_LINKS)}>Links</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_REASONCODES)}>Årsagsforklaringer</ContextMenuItem>
                  <ContextMenuItem onClick={() => navigateReact(ADMIN_QUESTIONS)}>Tjeklistespørgsmål</ContextMenuItem>
                </>
              }
            >
              <BuildIcon
                color={
                  !!adminTaskTypesMatch || !!adminComponentsMatch || !!adminReasonCodesMatch || !!adminQuestionsMatch
                    ? n1LightTheme.palette.text.primary
                    : n1LightTheme.palette.grey.black40
                }
              />
            </ContextMenuTab>
            <MenuItemText>Admin</MenuItemText>
          </MenuItem>
        )}
      </CenterContainer>

      <BottomContainer>
        <MenuItem isActive={!!portalMatch} onClick={() => navigateReact(PORTAL_ROUTE)}>
          <WorldIcon color={!!portalMatch ? n1LightTheme.palette.text.primary : n1LightTheme.palette.grey.black40} />
          <MenuItemText>Portalen</MenuItemText>
        </MenuItem>
        <MenuItem isActive={false}>
          <ContextMenuTab
            menuItems={
              <>
                <ContextMenuItem onClick={handleNavigateProfile}>Profil</ContextMenuItem>
                <ContextMenuItem onClick={handleOnClickLogOut}>Log ud</ContextMenuItem>
              </>
            }
          >
            <Avatar text={getUserInitials()} size={24} fontSize={12} />
          </ContextMenuTab>
          <MenuItemText>Profil</MenuItemText>
        </MenuItem>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.primary};
  width: 105px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TopContainer = styled.div`
  margin: ${(props) => props.theme.spacing(10)} 0;
`;

const CenterContainer = styled.div`
  margin-top: 0;
`;

const BottomContainer = styled.div`
  margin: auto 0 ${(props) => props.theme.spacing(5)} 0;
`;

export const MenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  box-sizing: border-box;
  padding-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};

  border-radius: 10px;
  border: ${(props) =>
    props.isActive ? '3.5px solid' + props.theme.palette.main.yellow.primary : '3.5px solid transparent'};
  color: ${(props) => (props.isActive ? props.theme.palette.text.primary : props.theme.palette.grey.black40)};
  cursor: pointer;
`;

const MenuItemText = styled.span`
  font-size: 10px;
`;

export default memo(LeftMenu, (prevProps: Props, props: Props) => {
  return (
    prevProps.unapprovedTasks === props.unapprovedTasks &&
    prevProps.createdInternalTasks === props.createdInternalTasks &&
    prevProps.userProfileState?.isLoaded === props.userProfileState?.isLoaded &&
    prevProps.userProfileState?.userProfile?.role === props.userProfileState?.userProfile?.role
  );
});
